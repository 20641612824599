.NavigationLink {
  margin-bottom: 20px;
  /*transition: all 1s ease-out;*/
  overflow-y: hidden;
  overflow-x: visible;
  max-height: 1000px;
  position: relative;
  padding-left: 20px;
  left: -20px;
  width: calc(100% + 20px);
}

.NavigationLink[data-collapsed="true"] .NestedLink {
  display: none;
}


.NavigationLink[data-collapsed="true"] .NavigationLink__label {
  margin-bottom: 0 !important;
}

.NavigationLink__label {
  color: #272d2d;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  position: relative;
  transition: color .2s ease-in-out;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  padding-right: 5px;
}

.NavigationLink__label:hover {
  color: #6188d7;
}

.NavigationLink__label--active,
.NavigationLink[data-nested-active="true"] .NavigationLink__label {
  color: #00c08b !important;
}

.NavigationLink__label--active::before,
.NavigationLink[data-nested-active="true"] .NavigationLink__label::before {
  content: '';
  position: absolute;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #00c08b;
  display: none;
}

.NavigationLink[data-collapsable="true"] .NavigationLink__label {
  margin-bottom: 20px;
  padding-right: 27px;
}

.NavigationLink[data-collapsable="true"] .NavigationLink__label .arrow-wrap {
  width: 27px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (min-width: 1280px) {
  .NavigationLink.ru.legal[data-collapsable="true"] .NavigationLink__label .arrow-wrap {
    right: 65px;
  }
}

.NavigationLink[data-collapsable="true"] .NavigationLink__label .arrow {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: rotate(225deg);
}

.NavigationLink[data-collapsed="true"] .NavigationLink__label .arrow {
  transform: rotate(45deg);
  bottom: 4px;
}

.NavigationLink > *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1279px) {
  .NavigationLink[data-collapsable="true"] .NavigationLink__label .arrow-wrap {
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
  }
}

.NavigationLink__label.back-arrow:before {
  content: '';
  position: absolute;
  z-index: 1;
  display: block;
  width: 14px;
  height: 7px;
  background-image: url('../../../../../../assets/icons/arrow.svg');
  background-position: right center;
  -webkit-background-size: cover;
  background-size: cover;
  transform: rotate(180deg);
  top: 0;
  left: -20px;
  bottom: 0;
  margin: auto;
}